import React from 'react';
import './NewsletterSection.scss';
import Popup from '../Popup';
import {faCheckCircle, faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class NewsletterSection extends React.Component {

    state: any;

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            isValid: false,
            textPopup: '',
            iconPopup: faCheckCircle,
            classIconPopup: 'text-validate'
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static defaultProps = {
        title: '',
        subtitle: ''
    };

    handleChange(event) {
        this.validate(event.target.value);
        this.setState({value: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        const email = this.state.value;
        fetch(`https://artdent-chirurgiens-dentistes.fr/.netlify/functions/addContactToSendinBlue`, {
            method: "POST",
            body: JSON.stringify({email})
        })
            .then(res => res.json())
            .then(data => {
                this.setState({textPopup: 'Votre inscription à la newsletter est confirmée !'});
                this.popup.togglePopup();
            })
            .catch(err => {
                this.setState({
                    textPopup: 'Une erreur est survenue lors de votre inscription ! Merci de réessayer ultérieurement.',
                    iconPopup: faExclamationCircle,
                    classIconPopup: 'text-error'
                });
                this.popup.togglePopup();
                console.error(err)
            });
    }


    validate(input) {
        if (typeof input !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input)) {
                return this.setState({
                    isValid: false
                });
            }
        } else {
            return this.setState({
                isValid: false
            });
        }
        return this.setState({
            isValid: true
        });
    }


    render() {
        const {title, subtitle} = this.props;

        return (
            <div className="NewsletterSection text-center py-8">
                {title && <h2 className="SoinsSection--Title mb-6">{title}</h2>}
                {subtitle && <h3 className="SoinsSection--Subtitle mb-6">{subtitle}</h3>}
                <hr/>

                <form onSubmit={this.handleSubmit} className="flex w-full md:w-3/5 lg:w-2/5  m-auto mt-8">
                    <label className="Form--Label">
                        <input
                            className="Form--Input Form--InputText mr-4"
                            type="text"
                            placeholder="Entrer votre email"
                            value={this.state.value}
                            onChange={this.handleChange}
                            required
                        />
                        <span>Entrer votre email</span>
                    </label>
                    <button type="submit" disabled={!this.state.isValid} value="Envoyer" className="Button">S'inscrire
                    </button>
                </form>

                <Popup ref={popup => this.popup = popup}>
                    <FontAwesomeIcon icon={this.state.iconPopup} className={this.state.classIconPopup}/>
                    <div>{this.state.textPopup}</div>
                </Popup>

                <hr className="w-4/5 m-auto mt-8"></hr>

            </div>
        )
    }
}

export default NewsletterSection
