import React from 'react'
import './Footer.scss'
import NewsletterSection from './Accueil/NewsletterSection';

export default () => (
    <footer className="footer m-auto p-8">
        <NewsletterSection title="Notre Newsletter" subtitle="Recevez les dernières informations de notre cabinet dans votre boite mail"/>
        <div className="flex justify-around items-center flex-col md:flex-row pl-4 pr-4 text-2xl links-container w-4/5 m-auto">
            <div className="flex flex-col mb-8 md:mb-0 nav-content-container">
                <div className="font-bold mb-2">PLAN DU SITE</div>
                <hr className="w-2/5 m-auto mt-4 mb-4"></hr>
                <a href="/">Accueil</a>
                <a href="/cabinet/">Le cabinet</a>
                <a href="/equipe/">Notre équipe</a>
                <a href="/soins/">Nos soins</a>
                <a href="/actualites/">Actualités</a>
            </div>
            <div className="flex flex-col mb-8 md:mb-0">
                <div
                    className="footer-logo hidden md:flex"
                    style={{
                        backgroundImage: `url(/images/artDent_logo-square.svg)`
                    }}
                />
            </div>
            <div className="flex flex-col mb-8 md:mb-0 nav-content-container">
                <div className="font-bold mb-2">INFORMATIONS</div>
                <hr className="w-2/5 m-auto mt-4 mb-4"></hr>
                <a href="/mentions-legales">Mentions Légales</a>
                <a href="/cgu">Conditions générales d'utilisation</a>
                <a target="_blank" href="https://www.legifrance.gouv.fr/codes/section_lc/LEGITEXT000006072665/LEGISCTA000006190548/">Charte déontologique</a>
                <a target="_blank" href="https://www.ordre-chirurgiens-dentistes.fr/">Ordre National</a>
                <a target="_blank" href="https://www.ordre-chirurgiens-dentistes.fr/annuaire/">Annuaires chirurgiens dentistes</a>
            </div>
        </div>

        <hr className="w-4/5 m-auto mt-4 mb-4"></hr>
        <div className="text-center text-2xl pl-4 pr-4 w-4/5 m-auto">
            <div>
                © {new Date().getFullYear()} Art Dent. Conception :
                <a href="https://pickbeam.com/" className="text-primary"> Pickbeam</a>.
            </div>
            <div className="mt-2 footer-phone-number">
                <a href="tel:0561510561" className="text-primary text-4xl">05 61 51 05 61</a>
            </div>
        </div>
    </footer>
)
